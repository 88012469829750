import React, {useState, useEffect, useCallback} from 'react';

import {LoadingIndicator, TwoSwitch} from "../../lib/iprs-react-library/src/index";

import home_icon from "./img/new/home-icon.svg";
import briefcase_icon from "./img/new/briefcase-icon.svg";
import location_pin from "./img/new/location-pin-icon.svg";

// import CustomGoogleMap from './CustomGoogleMap';

import Search from './Search';
import ClinicList from './ClinicList';
import OnwardReferralMap from './OnwardReferralMap';
import Legend from './Legend';
import ReferralMessage from './ReferralMessage';
import SearchResultWithMarkers from './SearchResultWithMarkers';
import ErrorMessage from './ErrorMessage';

// import closeIcon from './img/new/close-round-icon.svg';
import checkIcon from "./img/new/check-mark-circle-line-icon.svg";

import F2FReferralButton from "./F2FReferralButton";

import { LoadScript } from "@react-google-maps/api";
import { CustomPinMarker } from "../SharedComponents/Maps/CustomMarkerGenerator";

// import problemArr from './problemArr';

const api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY

// Variables that needs to be defined by pharos instead of using hardcoded ones.
// const devToken = sessionStorage.getItem('apiToken');
// let devToken;
// let devPartyID;
// let devCaseID;

// Hardcoded partyID for development
// devToken = "";
// devPartyID = "350837";    // partyID with home and work postcode
// devPartyID = "124569";   // PartyID with home postcode
// devCaseID = "540676";
// devPartyID = "350837";
// devCaseID = "506363";
// devCaseID = "540676";

// -----------------------------------------//
// partyID and patientID is identical
// -----------------------------------------//

// variable that determines whether to use hardcoded datas or ones from pharos.
// Just use empty string to use the params in url.
// let mode = "dev"
// let mode = ""
// -------------------

// --- Service IDs ---
// TICKET-566 requested to remove list of 'Modalities' in the search section of onward referral 
// but they want 'Services' section to remain. However the 'Services' are basically modalities 
// and it is contained in the array that contains modalities.
// These modalities needs to be displayed under 'Services' Heading. This array is used to
// filter out the modalities array that comes back from GetModalityList api request that
// will be listed under 'Services' heading.
const serviceIDs = [22, 1, 43, 25, 44, 24, 40, 39, 45, 26];

const OnwardReferralForm = ({api, getURLSearchParam}) => {

    let patientID, partyID, caseID;

    // When this script loads, it will checks for patientID and caseID in the url parameters
    // and it doesn't have url params, it will use default values set at the start of this script.
    // (devPartyID, devCaseID and devCaseID are the default values)
    {
        const patientIdUrlParam = getURLSearchParam('PatientID');
        if(patientIdUrlParam){
            patientID = patientIdUrlParam;
            partyID = patientID;
            caseID = getURLSearchParam("CaseID");
        }
    }


    const [legendData, setLegendData] = useState([
        { type: "location", name: "Home", icon: home_icon },
        { type: "location", name: "Work", icon: briefcase_icon },
        { type: "location", name: "Alternative", icon: location_pin }
    ]);

    const [userLocations, setUserLocations] = useState({
        home: "",
        work: "",
        alt: ""
    });

    const [ranking, setRanking] = useState([]);
    const [services, setServices] = useState([]);

    // Fixing the issue where OnwardReferral hangs when certain api response is received.
    // Swap the empty array to response array (problemArr) that is causing the issue.
    const [searchResult, setSearchResult] = useState([]);
    //const [searchResult, setSearchResult] = useState(problemArr);

    const [loader, setLoader] = useState(false);

    // Initialising all the checkboxes to false when search component first loads.
    const [postcodeCheckbox, setPostcodeCheckbox] = useState({
        homePostcode: false,
        workPostcode: false,
        altPostcodeCheckbox: false
    });

    const [test, setTest] = useState("testing");

    const [error, setError] = useState(null);

    // when api response is received, it comes in an array with objects.
    // The object has property name 'ContactMechanismTypeDescription' which contains
    // a string that describes whether the object contains location data of
    // home or work address. This function will set the userLocation state to contain
    // post code and gps coordinate of the location if it is present in the argument.
    // userLocations is then used in the map component that is loaded.
    const postcodeController = useCallback(() => {
        const params = {
            partyid: partyID
        };

        if (api.isLoggedIn() && partyID !== null) {
            api.transact("PostalAddressList", params)
                .then(r => r.apiResult)
                .then(addresses => {
                    const postcodes = {};
                    // filter out addresses which didn't lookup to a position (bad postcode)
                    addresses
                        .filter(
                            addr =>
                                null !== addr.Longitude &&
                                null !== addr.Latitude
                        )
                        .forEach(address => {
                            const postCode = {
                                postcode: address.Postcode,
                                coordinate: {
                                    lng: address.Longitude,
                                    lat: address.Latitude
                                }
                            };

                            if (
                                address.ContactMechanismTypeDescription ===
                                "Home address"
                            ) {
                                postcodes.home = postCode;
                            } else if (
                                address.ContactMechanismTypeDescription ===
                                "Business address"
                            ) {
                                postcodes.work = postCode;
                            }
                        });
                    setUserLocations(prev => {
                        return { ...prev, ...postcodes };
                    });
                    setError(null);
                })
                .catch(err => {
                    console.log(
                        "failed to load getToken PostalAddressList",
                        api.getToken(),
                        api.isLoggedIn()
                    );
                    setError(
                        "Failed to retrieve patient's postcodes. ",
                        err.message
                    );
                });
        } else {
            setError(
                JSON.stringify(
                    "Has user logged in?: " +
                        api.isLoggedIn() +
                        "partyID: " +
                        partyID
                )
            );
        }
    }, [api, partyID]);

    // validates token to see if user is logged in
    useEffect(() => {
        // This if statement checks for availability of partyID and if it does exist, it makes API request
        // to retrieve the postal address associated with it. Once response has been received, it runs
        // postcodeController function to set the userLocations state which will contain postcode and
        // gps coordinates of home and work.

        postcodeController();
        if (api.isLoggedIn() === true) {
            // Making API request to retrieve ranking list, which is used to set the marker with letters
            // which will be used in the search forms, legends and markers on the map.
            api.transact("PracticeRankingList", {})
                .then(r => {
                    setRanking(r.apiResult);
                    setError(null);
                })
                .catch(e => {
                    setError(JSON.stringify('PracticeRanking List api error: ' + e.message));
                });
            
                
            // Making API requests to get ranking and modalities 
            api.transact("GetModalityList", {})
            .then(r => {
                let service = []
                // filters the list of modalities that gets sent back from the API using 'serviceIDs' array.
                // The filtered list gets displayed under heading 'Services'
                const serviceFilter = (list) => {
                    list.forEach((item)=>{
                        if(serviceIDs.includes(item.pkModalityID)){
                            service.push(item);
                        }
                    })
                }
    
                serviceFilter(r.apiResult);
    
                setServices(service)
                setError(null)
    
            })
            .catch(e => {
                setError('GetModalityList api request failed: ', JSON.stringify(e.message));
            });

        }else{
            setError('Authentication failed, api.isLoggedIn(): ',  api.isLoggedIn())
        }
    }, [api, postcodeController]);

    // Generating markers to be displayed on the map using the google api with alphabet and colour data
    // given from API response.
    const [legendClinicMarker, setLegendClinicMarker] = useState([]);

    useEffect(() => {
        const alphabet = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z"
        ];
        if (ranking.length !== 0) {
            let markers = [];
            for (let i = 0; i < ranking.length; i++) {
                markers.push({
                    type: "ranking",
                    name: ranking[i].PracticeRankingDescription,
                    icon: CustomPinMarker(ranking[i].Colour, alphabet[i])
                });
            }
            setLegendClinicMarker(markers);
        }
    }, [ranking]);

    // Function to set searchResult state to [], which will subsequently change the left panel to display searchComponent
    // because searchComponent conditionally need populated searchResult array.
    const backToSearch = e => {
        e.preventDefault();
        setSearchResult([]);
    };

    // For google maps
    const [searchAgain, setSearchAgain] = useState(false);

    // 1. Check if clinic has been selected.
    const [selectedClinic, setSelectedClinic] = useState(null);

    // 2. Check if search again button is clicked.
    //    If searchAgain is true, it will set selectedClinic to null and
    //    this will swap ClinicAddress to ReferralSearch.
    useEffect(() => {
        if (searchAgain !== false) {
            // setSelectedClinic(null);
            setSearchAgain(false);
        }
    }, [searchAgain]);

    // Maximum number of characters setting for make referral message modal
    const messageMaxCharLength = 10000;

    const [loaderMessage, setLoaderMessage] = useState("");
    const [referralMessageOverlay, setReferralMessageOverlay] = useState(false);
    const [notesToAP, setNotesToAP] = useState("");

    useEffect(() => {
        // Limits the character length of the textarea to max value set in messageMaxCharLength
        if (notesToAP.length > messageMaxCharLength) {
            let noteString = notesToAP;
            noteString.substring(0, messageMaxCharLength);
            setNotesToAP(noteString);
        }
    }, [notesToAP]);

    // States to store dropdown values in referral message component.
    const [assessmentValue, setAssessmentValue] = useState(0);
    const [treatmentValue, setTreatmentValue] = useState(0);
    const [availableCaseDocuments, setAvailableCaseDocuments] = useState([]);
    const [caseDocumentsToSend, setCaseDocumentsToSend] = useState([]);

    const [justification, setJustification] = useState("");
    const [noMessagingToPatient, setNoMessagingToPatient] = useState(false);

    const [referralMessageStatus, setReferralMessageStatus] = useState(null);
    // this function will run when make referral button in the clinic list and button in info window of markers
    // are clicked. This sets the selectedClinic state to clinic given in the parameter as an argument and
    // opens up the referralMessageOverlay.
    // It also makes API request and set the notesToAP state which will be used to fill up the textarea
    // if content exists.
    const referralMessageHandler = (e, clinic) => {
        // don't reload the page
        e.preventDefault();

        setSelectedClinic(clinic);
        setReferralMessageOverlay(true);
        setNoMessagingToPatient(false);
    };

    const getNotesToAPHandler = useCallback(() => {
        setReferralMessageStatus(null);

        const params = {
            CaseID: caseID
        };

        api.transact("GetNotesToAP", params)
            .then(r => {
                setNotesToAP(r.apiResult.Notes);
                setAssessmentValue(r.apiResult.Assessments);
                setTreatmentValue(r.apiResult.Treatments);
                setAvailableCaseDocuments(r.apiResult?.CaseDocuments?.filter(obj => obj.Name.includes(".pdf")) ?? []); //only add pdf documents
                setError(null);
            })
            .catch(e => {
                setError("GetNotesToAP request error: " + e.message);
                setReferralMessageStatus(e.apiOK);
            });
    }, [api, caseID]);

    // This useEffect will run when this component loads and if caseID isnt empty, it will run getNotesToAPHandler function
    // which will set the states of notesToAP, assessmentValue and treatmentValue which is used for fields in the message
    // modal window that appears when makeReferral button or clinic markers on the map is clicked.
    useEffect(() => {
        if (caseID !== undefined) {
            console.log(caseID);
            getNotesToAPHandler();
        }
    }, [caseID, getNotesToAPHandler]);

    const [saveStatus, setSaveStatus] = useState(null);
    const [makeReferralResult, setmakeReferralResult] = useState(null);

    // Moved from Search component since it is needed to be included in the makeReferal params.
    const [gender, setGender] = useState("");

    // Make Referral Function
    const makeReferral = e => {
        // don't reload the page
        e.preventDefault();

        setLoaderMessage("");
        setSaveStatus(false);

        const params = {
            "notesToAP": notesToAP,
            "CaseID": caseID,
            "FacilityID": selectedClinic.pkFacilityID.toString(),
            "PatientID": patientID,
            "Assessment": assessmentValue,
            "Treatment": treatmentValue,
            "Gender": gender,
            "Justification": justification,
            "CaseDocuments": caseDocumentsToSend
        }
        // Warning: The justification field must be null or a string 
        // that exactly matches the justification in the database.
        // Making API request to make referral
        api.transact("MakeReferral", params)
            .then(r => {
                setLoaderMessage(r.apiText);
                if (r.apiStatus === "OK") {
                    setLoader(false);
                    setmakeReferralResult(r.apiText);
                    setSaveStatus(true);
                } else if (r.Status === "ERROR") {
                    setLoader(false);
                    setSaveStatus(false);
                    setmakeReferralResult(r.apiText);
                }
            })
            .catch(e => {
                setLoader(false);
                setSaveStatus(false);
                setmakeReferralResult(e.apiText);
            });
    };

    const [searchResultWithMarker, setSearchResultWithMarker] = useState([]);

    // When searchResultWithMarker is not [], it means search has been successful
    // and it will make GetAPNotes request using the function 

    // This function is used to search through an array with given query.
    const searchArray = (array, query) => {
        const searchQuery = query;
        const searchResult = array.filter((item) => {
            return (
                item.name.includes(searchQuery)
            );
        })

        return searchResult;
    }

    const notesToAPHandler = (e) => {
        const { value } = e.target;
        if (value.length <= messageMaxCharLength) {
          setNotesToAP(value);
        }
    }

    const referralMessageOverlayReturnHandler = (e) => {
        e.preventDefault()
        setReferralMessageOverlay(false)
        setNotesToAP('')
    }

    const [noteLength, setNoteLength] = useState(0);

    useState(()=>{
        console.log(notesToAP)
        if(notesToAP !== undefined){
            setNoteLength(notesToAP.length);
        }
    },[notesToAP])

    // States that will remember whether initial state has been performed or not.
    // setInitialSearchLoad state will turn to false in the 'Search' component
    // which happens in a useEffect which will run when userLocations state is 
    // changed.
    // ---Update---
    // WR-493 ticket: Disable the initial search. 
    // when initialSearchLoad is set to false and initialSearchLoadComplete is set to true
    // it disables the initial search. 
    // Initial search related codes are left for possible future requests to turn it back on.
    const [initialSearchLoad, setInitialSearchLoad] = useState(false);
    const [initialSearchLoadComplete, setInitialSearchLoadComplete] = useState(true);

    // async function to retrieve coordinates from google api.
    // This function will be used when user ticks alternative postcode 
    // and enters correct postcode which is determined by "PracticeSearch"
    // end point. 
    // It will also be used when searchResult has an clinic object with lat & lng 
    // that is 0 by using the postcode in it.
    const fetchCoordinateFromGoogleMap = useCallback(async (postcode) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${api_key}`;

        console.log('making request to googleapi: ', geocodeUrl)
        
        try {
            const response = await fetch(geocodeUrl)
            const data = await response.json();

            console.log('fetched...',geocodeUrl, 'received: ', data);
            
            if(data.results.length > 0){
                const { lat, lng } = data.results[0].geometry.location;
                return {lat, lng};
            } else {
                throw new Error('No results found for the given postcode');
            }
        } catch (error) {
            throw error;
        }
    },[])

    const getCoordinateForUserLocation = useCallback((userLocations) => {
        if(userLocations.home?.postcode !== '' && userLocations.home?.postcode !== undefined){
            console.log('user home postcode: ', userLocations.home.postcode)

            fetchCoordinateFromGoogleMap(userLocations.home.postcode)
                .then(r=>{

                    setUserLocations((prev)=>({
                        ...prev, 
                        home:{
                            ...prev.home,
                            coordinate: r
                        }}
                    ))

                })
                .catch(e=>{
                    setError('home postcode set for patient is incorrect.')
                })
        }
    },[fetchCoordinateFromGoogleMap])

    // The OnwardReferralMap component shouldn't be loaded unless there is at 
    // least one locations set in userLocations state.
    useEffect(()=>{

        if(userLocations !== undefined){

            if(userLocations.home === ''){
                setError('userLocations state (home) is empty string', userLocations.home)
            } else if(userLocations.home === undefined) {
                setError('userLocations state (home) is undefined', userLocations.home)
            }
            if(userLocations.home !== ''){
                if(userLocations.home?.coordinate.lng === 0 && userLocations.home?.coordinate.lat === 0){
                    getCoordinateForUserLocation(userLocations)
                }
            }
        } else {
            setError('userLocations is undefined')
        }
        
    },[userLocations, setUserLocations, getCoordinateForUserLocation]);

    return (
        <>
            <F2FReferralButton api={api} caseID={caseID} />
            <>
                <SearchResultWithMarkers
                    searchResult={searchResult} 
                    ranking={ranking} 
                    setSearchResultWithMarker={setSearchResultWithMarker}
                    searchResultWithMarker={searchResultWithMarker}
                    fetchCoordinateFromGoogleMap={fetchCoordinateFromGoogleMap}
                />
            </>
            {/* {error !== null ? <p>{JSON.stringify(error)}</p> : <></>} */}
            <TwoSwitch test={error} >
                <ErrorMessage error={error}/>
            </TwoSwitch>

            <div>
                <LoadScript googleMapsApiKey={api_key}>
                    {
                        loader ? (
                            <div className="onwardReferralLoader">
                                <div>
                                    <LoadingIndicator loading={'true'} color={'#ffffff'}/>
                                    <div>
                                        <p>Loading Contents ...</p>
                                        <p>{loaderMessage}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                    {
                        referralMessageOverlay ? (
                            <ReferralMessage 
                                caseID={caseID}
                                checkIcon={checkIcon}
                                assessmentValue={assessmentValue}
                                setAssessmentValue={setAssessmentValue}
                                treatmentValue={treatmentValue}
                                setTreatmentValue={setTreatmentValue}
                                referralMessageStatus={referralMessageStatus}
                                saveStatus={saveStatus}
                                makeReferralResult={makeReferralResult}
                                notesToAPHandler={notesToAPHandler}
                                referralMessageOverlayReturnHandler={referralMessageOverlayReturnHandler}
                                noteLength={noteLength}
                                selectedClinic={selectedClinic}
                                notesToAP={notesToAP}
                                messageMaxCharLength={messageMaxCharLength}
                                makeReferral={makeReferral}
                                setLoaderMessage={setLoaderMessage}
                                setReferralMessageOverlay={setReferralMessageOverlay}
                                setJustification={setJustification}
                                justification={justification}
                                availableCaseDocuments={availableCaseDocuments}
                                setAvailableCaseDocuments={setAvailableCaseDocuments}
                                caseDocumentsToSend={caseDocumentsToSend}
                                setCaseDocumentsToSend={setCaseDocumentsToSend}
                                noMessagingToPatient={noMessagingToPatient}
                                setNoMessagingToPatient={setNoMessagingToPatient}
                            />
                        ) : (<></>)
                    }
                    <div className="onwardReferralMainContent">
                        <div className="onwardReferralSearchSection">
                            {searchResult?.length === 0 ? (
                                <>
                                    <p className="searchResultStatus">
                                        {searchResult?.length !== undefined &&
                                        searchResult?.length > 0
                                            ? "searchResult: " + searchResult
                                            : ""}
                                    </p>
                                    <Search
                                        googleMapsApiKey={api_key}
                                        api={api}
                                        partyID={partyID}
                                        userLocations={userLocations} 
                                        setUserLocations={setUserLocations} 
                                        ranking={ranking} 
                                        setSearchResult={setSearchResult}
                                        postcodeCheckbox={postcodeCheckbox}
                                        setPostcodeCheckbox={
                                            setPostcodeCheckbox
                                        }
                                        test={test}
                                        setTest={setTest}
                                        services={services}
                                        setLoader={setLoader}
                                        setError={setError}
                                        initialSearchLoad={initialSearchLoad}
                                        setInitialSearchLoad={
                                            setInitialSearchLoad
                                        }
                                        setInitialSearchLoadComplete={
                                            setInitialSearchLoadComplete
                                        }
                                        initialSearchLoadComplete={
                                            initialSearchLoadComplete
                                        }
                                        fetchCoordinateFromGoogleMap={
                                            fetchCoordinateFromGoogleMap
                                        }
                                        gender={gender}
                                        setGender={setGender}
                                        caseID={caseID}
                                    />
                                </>
                            ) : (
                                <p className="searchResultStatus">
                                    {searchResult === undefined
                                        ? "searchResult is undefined"
                                        : ""}
                                    {searchResult?.length === 0
                                        ? "searchResult is 0" + searchResult
                                        : ""}
                                </p>
                            )}
                            {searchResult?.length > 0 ? (
                                <>
                                    <p className="searchResultStatus">
                                        {searchResult === undefined
                                            ? "searchResult is undefined"
                                            : ""}
                                        {searchResult?.length === 0
                                            ? "searchResult is 0"
                                            : ""}
                                        {searchResult?.length > 0
                                            ? searchResult.length +
                                              " Clinics Found"
                                            : ""}
                                    </p>
                                    <ClinicList
                                        backToSearch={backToSearch}
                                        searchResult={searchResult}
                                        legendData={legendData}
                                        setLegendData={setLegendData}
                                        makeReferral={makeReferral}
                                        legendClinicMarker={legendClinicMarker}
                                        searchArray={searchArray}
                                        searchResultWithMarker={
                                            searchResultWithMarker
                                        }
                                        referralMessageHandler={
                                            referralMessageHandler
                                        }
                                        partyID={partyID}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        {userLocations !== null ? (
                            <div className="mapSection">
                                <Legend
                                    ranking={ranking}
                                    legendClinicMarker={legendClinicMarker}
                                />
                                <OnwardReferralMap
                                    api={api}
                                    searchResult={searchResult}
                                    userLocations={userLocations}
                                    selectedClinic={selectedClinic}
                                    postcodeCheckbox={postcodeCheckbox}
                                    ranking={ranking}
                                    makeReferral={makeReferral}
                                    legendClinicMarker={legendClinicMarker}
                                    searchResultWithMarker={
                                        searchResultWithMarker
                                    }
                                    referralMessageHandler={
                                        referralMessageHandler
                                    }
                                    fetchCoordinateFromGoogleMap={
                                        fetchCoordinateFromGoogleMap
                                    }
                                />
                            </div>
                        ) : (
                            <p class="onwardReferralSearchForm">
                                User location is not available...
                            </p>
                        )}
                    </div>
                </LoadScript>
            </div>
        </>
    );
};

export default OnwardReferralForm;